import React, { useEffect, useRef, useState } from 'react';

import { Widget } from '@typeform/embed-react';
import { easeOut, motion, useScroll, useTransform } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useGlobalState } from '../../state/globalStateContext';
import {
  useStoreReferrerOnLoad,
  useStoreScheduleOnceCalendarOnLoad,
  useStoreUTMParamsOnLoad,
} from '../../utils/hooks';
import {
  clsx,
  generateUniqueId,
  getLocalStorageMemoizedValue,
  withDataLayer,
} from '../../utils/utils';
import ButtonLink from '../ui/ButtonLink';
import * as styles from './CtaSection.module.scss';
export interface CtaSectionProps {
  title: string;
  text: string;
  formType?: 'typeform' | 'calendar' | 'none';
  url: string;
  typeformId?: string;
  className?: string;
}

const CtaSection = ({
  title,
  text,
  formType,
  url,
  typeformId,
  className,
}: CtaSectionProps): React.ReactElement => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ['start 120%', 'start 0%'],
  });

  const backgroundTranslateYMV = useTransform(scrollYProgress, [0, 1], ['20vh', '0vh'], {
    ease: easeOut,
  });
  const contentTranslateYMV = useTransform(scrollYProgress, [0, 1], ['-15vh', '0vh'], {
    ease: easeOut,
  });

  const { scheduleOnceCalendar } = useGlobalState();
  const socalendarScheduleEventTriggered = useRef(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();
  useStoreScheduleOnceCalendarOnLoad();

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  useEffect(() => {
    if (formType === 'calendar' && scheduleOnceCalendar) {
      window.addEventListener(
        'message',
        event => {
          if (
            event.origin === 'https://go.oncehub.com' &&
            event.data?.bookingData &&
            event.data?.bookingData?.errorStatus === 0 &&
            !socalendarScheduleEventTriggered.current
          ) {
            withDataLayer(dataLayer => {
              dataLayer.push({
                event: 'socalendar-schedule',
                calendar: scheduleOnceCalendar,
                uniqueId: getLocalStorageMemoizedValue(
                  'randomly_generated_unique_id',
                  generateUniqueId,
                ),
              });
            });
            socalendarScheduleEventTriggered.current = true;
          }
        },
        false,
      );
    }
  }, [scheduleOnceCalendar]);

  return (
    <div
      ref={sectionRef}
      className={clsx(
        styles.container,
        (formType === 'typeform' || formType === 'calendar') && styles.withForm,
        className,
      )}
    >
      <motion.div className={styles.background} style={{ y: backgroundTranslateYMV }}></motion.div>
      <motion.div className={styles.content} style={{ y: contentTranslateYMV }}>
        <div className={styles.textContainer} id="cta-section">
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
          {(!formType || formType === 'none') && (
            <ButtonLink
              onClick={() => {
                withDataLayer(dataLayer => {
                  dataLayer.push({ event: 'cta-button-click', context: 'cta-section' });
                });
              }}
              to={url}
              className={styles.button}
            >
              Schedule a call now
            </ButtonLink>
          )}
        </div>
        <div className={styles.formContainer}>
          {formType === 'typeform' && typeformId && (
            <Widget inlineOnMobile id={typeformId} className={styles.form} />
          )}
          {formType === 'calendar' && (
            <>
              <Helmet>
                {hasLoaded && (
                  // ScheduleOnce embed
                  <script
                    type="text/javascript"
                    src="https://cdn.oncehub.com/mergedjs/so.js"
                  ></script>
                )}
              </Helmet>
              {scheduleOnceCalendar && (
                <div
                  id={'SOIDIV_' + scheduleOnceCalendar}
                  data-so-page={scheduleOnceCalendar}
                  data-height="550"
                  data-style="border: 1px solid #D8D8D8; min-width: 290px; max-width: 900px;"
                  data-psz="10"
                ></div>
              )}
            </>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default CtaSection;
